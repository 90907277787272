// Gallery

.gallery-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    &.left {
        justify-content: flex-start;
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;
    }
}
.gallery-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    img,
    picture {
        /*width: 100%;*/
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    }
}
.product-menu-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    padding: 3rem 0;

    a:hover {
        text-decoration: none;
    }

    .product-menu-item {
        display: flex;
        justify-content: center;
        flex: 0 0 100%;
        padding: 1rem;

        .product-link {
            position: relative;
            overflow: hidden;
            color: $white;

            &:hover {
                .menu-item-overlay {
                    transform: translateY(0);
                }

                &.no-img:after {
                    width: 100%;
                }
            }

            &.no-img:after {
                content: '';
                position: absolute;
                top: 0;
                display: block;
                transition: width 0.2s;
                width: 0;
                height: 3px;
                background: $orange;
            }
        }

        /*.menu-item-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateY(-100%);
            transition: transform 0.2s;
            border-top: 3px solid $orange;

            .bg-img-overlay {
                width: 100%;
                height: 100%;
                background-size: cover;
                padding: 0.5rem;
                font-size: 1.75rem;
                line-height: 1;

                .svg-icon {
                    stroke-width: 4px;
                }
            }
        }*/

        .menu-item-inner {
            background: $gray-secondary-transparent-10;
            width: 8.375rem;
            height: 8.375rem;
            padding: 0.75rem;
            text-align: center;

            .product-title,
            .product-subtitle {
                display: block;
                text-transform: uppercase;
                font-size: 1rem;
                line-height: 1.1;
                text-align: left;
            }

            .product-subtitle {
                font-weight: $font-weight-medium;
                color: $orange;
            }

            .icon-wrapper {
/*                margin: 0.5rem auto;
                width: 4.25rem;
                height: 4.25rem;

                img {
                    width: 100%;
                    height: auto;
                }*/

                .svg-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    pointer-events: none;

                    @include media-breakpoint-up(md){
                        pointer-events: initial;
                    }
                }
            }


            &:hover {
                .icon-wrapper {
                    .svg-icon {
                        color: $secondary;
                    }
                }
            }
        }
    }
}

.bg-img-primary {
    .product-menu-container {
        .product-menu-item {
            .menu-item-inner {
                background: $gray-main url("/typo3conf/ext/wvier_speciality/Resources/Public/Images/bg-dark.png");
            }
        }
    }
}

.bg-img-dark {
    .product-menu-container {
        .product-menu-item {
            .menu-item-inner {
                background: $gray-secondary-transparent-10;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .product-menu-container {
        padding: 2rem 0 2.5rem;

        .product-menu-item {
            flex: 0 0 33.333%;
            width: 33.333%;
            max-width: 11.375rem;
        }
    }
}

@include media-breakpoint-up(md){
    .product-menu-container {
        .product-menu-item {
            .menu-item-inner {
                .product-title,
                .product-subtitle {
                    word-break: break-all;
                }
            }
        }
    }

    .ge-image-container {
        .product-menu-container {
            .product-menu-item {
                &.item-count-2 {
                    flex: 0 0 50%;
                    width: 50%;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl){
    .product-menu-header {
        padding: 3.25rem 4.25rem 1rem;

        a {
            &:hover {
                text-decoration: none;
            }
        }

        .title,
        .subtitle {
            display: block;
            font-size: $font-size-big-title-md;
            line-height: $line-height-big-title-md;
            text-transform: uppercase;
        }

        .subtitle {
            font-weight: $font-weight-medium;
        }
    }
}

@media (min-width: 1400px){

    .mega-menu-container {
        .product-menu-container {
            .product-menu-item {
                .menu-item-inner {
                    width: 9.375rem;
                    height: 9.375rem;

                    .icon-wrapper {
                        width: 6.25rem;
                        height: 6.25rem;
                    }

                    .product-title,
                    .product-subtitle {
                        font-size: $font-size-link-md;
                    }
                }
            }
        }
    }
}

@media (min-width: 1540px){
    .product-menu-header {
        padding: 3.25rem 5.625rem 1rem;
    }

    .mega-menu-container {
        .product-menu-container {
            .product-menu-item {
                max-width: 13.5625rem;

                .menu-item-inner {
                    width: 11.5625rem;
                    height: 11.5625rem;
                    padding: 1rem 1.65rem;

                    .icon-wrapper {
                        width: 6.25rem;
                        height: 6.25rem;
                    }
                }
            }
        }
    }
}

/* cookieconsent styling */

.cc-window {
    position: fixed;
    overflow: hidden;
    opacity: 1;
    transition: opacity 1s ease;
    font-size: 1rem;
    z-index: 99999;
    background-color: $gray-200;
    color: $black;
    padding: 1rem;

    /* Position */
    width: 100%;
    max-width: 460px;

    /* Center */
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.cc-hide {
    visibility: hidden !important;
    opacity: 0 !important;
}

.cc-window.cc-invisible {
    opacity: 0;
}

.cc-grower {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s;
}

.cc-revoke {
    display: none;
    visibility: hidden;
}

.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
    cursor: pointer;
}

.cc-link {
    opacity: 0.8;
    display: inline-block;
    text-decoration: underline;

    &:hover {
        opacity: 1;
    }

    &:focus {
        outline: 1px dotted;
    }

    &:active,
    &:visited {
        color: initial;
    }
}

.cc-btn,
.db--overlay-submit {
    display: block;
    padding: 0.4rem 0.8rem;
    border-width: 1px;
    border-radius: $border-radius;
    border-style: solid;
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    color: $white;
    border-color: $primary;
    background-color: $primary;

    &:hover,
    &:focus {
        color: $white;
        background-color: darken($primary, 7%)!important;
        border-color: darken($primary, 7%);
        text-decoration: none;
    }

    &:focus {
        outline: 1px dotted;
    }
}

.cc-highlight {
    .cc-btn {
        &.cc-allow {
            background-color: transparent;
            border-color: transparent;
            color: $primary;
            text-decoration: underline;

            &:focus,
            &:hover {
                background-color: transparent !important;
                text-decoration: underline;
            }
        }
        &.cc-allow-all {
            font-size: 1.25rem;
        }

        &.cc-deny {
            display: none;
        }
    }
}


.cc-close {
    display: block;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    font-size: 1.6em;
    opacity: 0.9;
    line-height: 0.75;
}

.cc-close:focus,
.cc-close:hover {
    opacity: 1;
}

.cc-message {
    display: block;
    padding: 0 0.5rem;
}


.cc-compliance {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    .cc-btn {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media print {
    .cc-revoke,
    .cc-window {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .cc-btn {
        white-space: normal;
    }
}

@media screen and (max-width: 419px) and (orientation: portrait),
screen and (max-width: 767px) and (orientation: landscape) {

    .cc-window{
        top: 0;
        left: 0;
        right: 0;
        bottom: unset;
        transform: translateX(0);
        max-width: none;
    }
}

@media screen and (min-width: 768px) {
    .cc-window {
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 1.75rem;
    }
}

body.dp--cookie-consent {
    &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($dark, 0.4);
        z-index: 1090;
    }
}

.dp--cookie-check {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0;

    label {
        padding: 5px;
        display: block;
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.dp--iframe {
     display: none;

     &.dp--loaded {
         display: block;
     }
 }

.dp--overlay {
    padding: 2.5rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid $gray-100;
}

.embed-responsive-16by9 {
    .dp--overlay {
        margin-top: -56.25%;
    }
}

.dp--overlay-header {
    font-size: $h3-font-size;
    font-weight: 500;
    color: $orange;
}

.dp--overlay-description {
    margin-top: 0.8rem;
}

.dp--overlay-button {
    margin-top: 0.8rem;
}

.db--overlay-submit {
    display: inline-block;
    cursor: pointer;
}

.dp--iframe.dp--loaded+.dp--overlay {
    display: none
}

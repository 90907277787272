@import "../../Contrib/MagnificPopup/main";


.mfp-fade {
    /* overlay at start */
    .mfp-bg {
        opacity: 0;
        transition: all 0.15s ease-out;
        /* overlay animate in */
        &.mfp-ready {
            opacity: 0.8;
        }

        /* overlay animate out */
        &.mfp-removing {
            opacity: 0;
        }
    }

    /* content at start */
    &.mfp-wrap .mfp-content {
        opacity: 0;

        transition: all 0.15s ease-out;
    }
}


/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}
@import "variables";
//Bootstrap Required
@import "../../Contrib/Bootstrap/scss/functions";
@import "../../Contrib/Bootstrap/scss/variables";
@import "../../Contrib/Bootstrap/scss/mixins";
//Bootstrap Optional
//@import "../../Contrib/Bootstrap/scss/root";
//@import "../../Contrib/Bootstrap/scss/reboot";
//@import "../../Contrib/Bootstrap/scss/type";
@import "../../Contrib/Bootstrap/scss/images";
//@import "../../Contrib/Bootstrap/scss/code";
//@import "../../Contrib/Bootstrap/scss/transitions";
//@import "../../Contrib/Bootstrap/scss/grid";
@import "../../Contrib/Bootstrap/scss/tables";
//@import "../../Contrib/Bootstrap/scss/forms";
//@import "../../Contrib/Bootstrap/scss/buttons";
@import "../../Contrib/Bootstrap/scss/dropdown";
//@import "../../Contrib/Bootstrap/scss/button-group";
//@import "../../Contrib/Bootstrap/scss/input-group";
//@import "../../Contrib/Bootstrap/scss/custom-forms";
//@import "../../Contrib/Bootstrap/scss/nav";
//@import "../../Contrib/Bootstrap/scss/navbar";
//@import "../../Contrib/Bootstrap/scss/card";
@import "../../Contrib/Bootstrap/scss/breadcrumb";
//@import "../../Contrib/Bootstrap/scss/pagination";
//@import "../../Contrib/Bootstrap/scss/badge";
//@import "../../Contrib/Bootstrap/scss/jumbotron";
//@import "../../Contrib/Bootstrap/scss/alert";
//@import "../../Contrib/Bootstrap/scss/progress";
//@import "../../Contrib/Bootstrap/scss/media";
@import "../../Contrib/Bootstrap/scss/list-group";
//@import "../../Contrib/Bootstrap/scss/close";
//@import "../../Contrib/Bootstrap/scss/toasts";
//@import "../../Contrib/Bootstrap/scss/modal";
//@import "../../Contrib/Bootstrap/scss/tooltip";
//@import "../../Contrib/Bootstrap/scss/popover";
//@import "../../Contrib/Bootstrap/scss/carousel";
//@import "../../Contrib/Bootstrap/scss/spinners";
@import "../../Contrib/Bootstrap/scss/utilities";
@import "../../Contrib/Bootstrap/scss/print";

// Custom Styles
@import "gallery";
@import "magnific";
@import "productmenu";
@import "dpcookieconsent";

@each $color, $value in $theme-colors {
    .swatch-#{$color} {
        color: color-yiq($value);
        background-color: $value;
    }
}

main {
    overflow-x: hidden;
}

p > .btn {
    margin: 2rem 0.5rem 0 0;
}

.text-small {
    font-size: 80%;
}

a,
button {
    &:focus {
        outline: none;
    }
}

.btn {
    &.btn-share {
        display: inline-flex;
        align-items: center;
        gap: .45em;
        flex-grow: 0;
        flex-shrink: 0;

        &::after {
            height: 1em;
            width: 1em;
            content: '';
            display: inline-block;
            flex-grow: 0;
            flex-shrink: 0;
            background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%20width%3D%2216%22%20height%3D%2216%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M307%2034.8c-11.5%205.1-19%2016.6-19%2029.2l0%2064-112%200C78.8%20128%200%20206.8%200%20304C0%20417.3%2081.5%20467.9%20100.2%20478.1c2.5%201.4%205.3%201.9%208.1%201.9c10.9%200%2019.7-8.9%2019.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8%20431.9%2096%20414.4%2096%20384c0-53%2043-96%2096-96l96%200%200%2064c0%2012.6%207.4%2024.1%2019%2029.2s25%203%2034.4-5.4l160-144c6.7-6.1%2010.6-14.7%2010.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z%22%2F%3E%3C%2Fsvg%3E");
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

// Lists
main {
    ul {
        list-style-image: url('#{$listimage-url}');
        padding-left: 1.125rem;

        li{
            padding-left: 0.625rem;
        }
    }
}

// IMG Zoom on hover
.img-zoom-outer {
    .img-zoom-wrapper {
        overflow: hidden;

        img {
            transform: scale(1.025);
            transition: 0.4s ease;
            transform-origin: 0 0;
        }
    }

    &:hover {
        .img-zoom-wrapper {
            img {
                transform: scale(1);
            }
        }
    }

}

.mfp-image {
    overflow: hidden;
    display: block;

    img {
        transform: scale(1.025);
        transition: 0.4s ease;
        transform-origin: 0 0;
    }

    &:hover {
        img {
            transform: scale(1);
        }
    }
}

.textpic-gallery {
    .svg-icon {
        position: absolute;
        stroke-width: 4px;
        top: -1rem;
        left: -1rem;
        font-size: 1.75rem;
        color: $secondary;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.owl-carousel .owl-item .mfp-image img.owl-lazy {
    transition: 0.4s ease;
}

@include media-breakpoint-up(md) {
    .c-type-slogan,
    .c-type-cta,
    .c-type-productTeaser,
    .c-type-textpic,
    .c-type-person,
    .ge-image-container {
        overflow: hidden;
    }

    .animation-fade-in.off-screen {
        transition: 0.8s ease;
        opacity: 0;

        &.animation-move-right {
            transform: translate(-80px, 0);
        }

        &.animation-move-left {
            transform: translate(80px, 0);
        }

        &.animation-move-up {
            transform: translate(0, 80px);
        }

        &.animation-move-up2 {
            transform: translate(0, 40px);
        }

        &.animated {
            transform: translate(0, 0);
            opacity: 1;
        }
    }
}

// Skiplinks
.skiplinks {
    .sr-only {
        &:focus {
            display: inline-block;
            padding: 0.5rem;
            width: auto;
            height: auto;
            overflow: visible;
            clip: auto;
            white-space: normal;
            background-color: $white;
            z-index: $zindex-tooltip;
        }
    }
}

// Layout
.container {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

//
// Scrolltop
//
.scroll-top {
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    z-index: $zindex-sticky;
    background-color: $gray-600;
    color: $white;
    bottom: ($grid-gutter-width / 2);
    right: ($grid-gutter-width / 2);
    position: fixed;
    opacity: 0;
    width: 3em;
    height: 3em;
    &:active,
    &:focus,
    &:hover {
        color: $white;
        background-color: $primary;
    }
    .scroll-top-icon {
        @extend .css-arrow;
    }
    &.scroll-top-visible {
        opacity: 1;
    }
}

.css-arrow {
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    &:before {
        content: '';
        position: absolute;
        height: 30%;
        width: 30%;
        border-top: 1px solid;
        border-left: 1px solid;
        top: 55%;
        left: 55%;
        transform: translate(-50%, -50%);
    }
}

.social-link-container {
    .social-list {
        display: flex;
        list-style: none;
        margin: 0 -0.5rem;
        padding: 0;

        .list-item {
            padding: 0.5rem;

            .svg-icon {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
}

.job-detail-body {
    .textpic-gallery {
        .img-fluid {
            width: 100%;
        }
    }
}


// Footer
.footer {
    .footer-content {
        .footer-brand {
            .icon-ascendor-logo {
                max-width: 24.375rem;
                width: 100%;
                height: auto;
            }
        }

        .footer-contact {
            padding: 3rem 0 0;

            .address {
                margin: 0 0 2rem;

                span {
                    display: block;
                }
            }

            .phone,
            .fax,
            .mail {
                display: block;
            }
        }
    }

    .footer-menu {
        display: flex;
        flex-wrap: wrap;

        nav {
            flex: 0 0 50%;
            width: 50%;
            //text-transform: uppercase;
        }
    }

    .list-inline {
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: 1.8;
        display: flex;
        flex-direction: column;
        align-items: baseline;
/*
        .list-inline-item {
            display: block;
        }*/
    }
}

@include media-breakpoint-up(md) {
    .footer {
        .container {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
        }

        .footer-half-left {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .footer-half-right {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .footer-content {
            .footer-brand {
                .icon-ascendor-logo {
                    padding-top: 1rem;
                }
            }
        }

        .social-link-container {
            .social-list {
                .list-item {
                    padding: 0 0.5rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .footer {
        .container {
            font-size: $font-size-body-xl;
            align-items: flex-start;
        }

        .footer-half-left {
            flex: 0 0 40%;
            max-width: 40%;
        }

        .footer-half-right {
            flex: 0 0 60%;
            max-width: 60%;
        }

        .footer-content {
            display: flex;
            flex-direction: column;

            .footer-brand {
                .icon-ascendor-logo {
                    padding: 0;
                }
            }

            .footer-contact {
                padding: 3rem 0 0 0;

                .address {
                    margin: 0;

                    span {
                        display: inline-block;
                        margin-right: 0.25rem;

                        &:first-child {
                            &:after {
                                content: ',';
                            }
                        }
                    }
                }

                .phone {
                    margin-right: 0.25rem;
                }

                .fax {

                }
            }
        }


        .footer-menu {
            //text-align: right;
            nav {
                flex: 0 0 100%;
                width: 100%;
            }
        }

        .list-inline {
            margin: 0 0 0.5rem;
            overflow: hidden;

            .list-inline-item {
                display: inline-block;
                //margin-right: -1px;
                //border-right: 1px solid $white;
                //padding: 0 0.5rem;
                //line-height: 1;
            }
        }

        .social-link-container {
            .social-list {
                justify-content: flex-end;
            }
        }
    }
}
.animation-frame-container {
    max-width: 1024px;
    margin: 0.5rem auto;

    .animation-frame-wrapper {
        position: relative;
        padding: 79.8333% 0 0;

        #animationFrame {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

@include media-breakpoint-up(sm) {
    .fixed-cta {
        position: fixed;
        right: 0;
        top: 30%;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        margin-right: -55px;
        align-items: center;

        a {
            transform: rotate(90deg);
            width: auto;
            text-align: left;

            &.button-prospekt {
                border-right: 0;
            }

            &.button-angebot {
                transform: rotate(90deg) translateX(107px);
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .fixed-cta {
        margin-right: -56px;
        a {

            &.button-angebot {
                transform: rotate(90deg) translateX(114px);
                margin-right: 1px;
            }
        }
    }
}
